import React from "react"


import { StaticImage }from "gatsby-plugin-image"

import { KontaktWrapper,KontaktSection, BigTitle,UpDown, TextKontakt,Text, BoxKontakt, TitleKontakt, BoxInfo, Box, BoxTelefon1, BoxLocalionKod,BoxHoursWeek } from "./KontaktStyled"
import { BoxFooter, LinkStyled, CopyrightText } from "../Footer/FooterStyled"


const Kontakt = () => {
  return (
  <>

  <KontaktSection>
<KontaktWrapper>
          <BigTitle style={{justifyContent: 'center', marginBottom:'5px'}}
          whileInView={{x: 0, opacity:1}}
          initial={{ x:-100, opacity:0}}
          transition={{delay:0.55, duration: 0.75}}
          >Kontakt
          </BigTitle>

<BoxKontakt>

  <TextKontakt>

    <UpDown whileInView={{ y: [-6, 6] }}
      transition={{ repeat: "Infinity",  repeatType: "reverse", duration: 1.2}}>

<a href="tel:+48608345265">
   <StaticImage 
    src="./iconTelefon2.png"
    alt="Telefon do weterynarza w Kaliszu"
    width={100}
    placeholder="blurred"
    imgClassName="imagetelefon"
    />
</a>
    </UpDown>

    <TitleKontakt>Umów wizytę</TitleKontakt> 
  </TextKontakt>
    <Text>Wyróżnia Nas wysoka jakość i zaangażowanie, nowoczesne wyposażenie, wykwalifikowany personel składający się z dyplomowanych lekarzy weterynarii, jak również szeroki wachlarz usług, a naszą misją jest niesienie pomocy chorym zwierzętom, a także profilaktyka w zakresie chorób zwierząt. <br /> Otaczamy je najlepszą opieka, a szczegółowa diagnostyka chorób przyśpiesza rozpoznanie i ułatwia wprowadzenie skutecznej terapii.</Text>
</BoxKontakt>

    </KontaktWrapper>


<BoxInfo>
<Box>
  <div>
  <StaticImage
    src="./iconTelefon.png"
    alt="Weterynarz Kalisz"
    width={64}
    height={64}
    placeholder="blurred"
    />
</div>

    <BoxTelefon1 href="tel:+48627573866">+48 62 757 38 66</BoxTelefon1>
    <BoxTelefon1 href="tel:+48608345265">+48  608 345 265</BoxTelefon1> 

</Box>
<Box>
  <div>
<StaticImage
    src="./iconLocation.png"
    alt="Adres weterynarza Kalisz"
    width={64}
    height={64}
    placeholder="blurred"
    />
    </div>
 <BoxLocalionKod> 62-800 Kalisz, <br />ul. Lipowa 17-21</BoxLocalionKod>

</Box>
<Box>
  <div>
<StaticImage
    src="./iconHour.png"
    alt="Lecznica czynna w godzinach"
    width={64}
    height={64}
    placeholder="blurred"
    />
 </div>
  <BoxHoursWeek>Poniedziałek - Piątek 10:00-20:00 <br />Sobota 9:00-14:00<br />Niedziela 10:00-12:00</BoxHoursWeek>
  
</Box>
</BoxInfo>
<div className="container-iframe">
    <iframe className="responsive-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2469.8315423682525!2d18.080373816134294!3d51.754403900433445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ac5946407f829%3A0xc1e5453d9bd8eb9b!2sWitczak%20S%C5%82awomir%2C%20lek.%20wet.%20Lecznica%20dla%20zwierz%C4%85t!5e0!3m2!1spl!2spl!4v1645184222307!5m2!1spl!2spl" loading="lazy" title="mapa google"></iframe>
    </div>

    <BoxFooter
whileInView={{y:0, opacity:1}}
initial={{ y:30, opacity:0}}
transition={{delay:0.95, duration:0.75}}
>
  <LinkStyled to="/polityka-prywatnosci" rel="nofollow">Polityka prywatności</LinkStyled>
           
        <CopyrightText>
          © {new Date().getFullYear()}, Wszalkie prawa zastrzeżone
          {` `} 
          <a href="https://www.weterynarz-witczak.pl">Lecznica dla Zwierząt</a>
       </CopyrightText>
    
    </BoxFooter>

 </KontaktSection>
</>
)
  }

export default Kontakt