import styled from "styled-components";
import { motion } from "framer-motion"
import {Link} from 'gatsby'

export const KontaktSection = styled.div`
width:100% ;
background-color: ${({ theme }) => theme.colors.white};

`;


export const KontaktWrapper = styled.div`
font-family: 'Montserrat', sans-serif;
width: 100%;
max-width: 1534px;
background-color:${({ theme }) => theme.colors.white};
margin: 0 auto;
padding-left: 5%;
padding-right: 5%;

`;

export const BigTitle = styled(motion.h2)`

font-size: clamp(5rem, calc(5rem + ((1vw - 0.36rem) * 7.1429)), 11rem);
  min-height: 0vw;
display:flex;
justify-content: end;
color: ${({ theme }) => theme.colors.lightGreen};
font-weight:${({ theme }) => theme.fontWeight.fontWeightBold};
text-shadow: 3px 5px 5px rgba(118, 128, 112, 1);
`;

export const UpDown = styled(motion.div)`

`

export const TextKontakt = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items:  center;
margin:20px;
gap:15px;



@media screen and (max-width: 768px) {
  width: 100%;
  gap:15px;
}
`;
/*
export const NumberTel = styled.h2`
font-size: 1.5rem;
color: ${({ theme }) => theme.colors.black};
font-weight: ${({ theme }) => theme.fontWeight.fontWeightBold};

@media screen and (max-width: 768px) {
text-align: center; 
}
`; */

export const Text = styled.p`
font-family: 'Montserrat', sans-serif;
font-size: 1.1rem;
line-height: 1.5;
`;


export const BoxKontakt = styled.div`
display: flex;
flex-direction:column ;
align-items:center ;

@media screen and (max-width: 768px) {
width: 100%;
display: flex;
flex-direction: column;
justify-content:center ;
align-items:center;
}
`;


export const TitleKontakt = styled.h1`
font-family: 'Montserrat', sans-serif;
font-size:clamp(2.5rem, calc(2.5rem + ((1vw - 0.36rem) * 0.5952)), 3rem);
  min-height: 0vw;
font-weight:${({ theme }) => theme.fontWeight.fontWeightBold};
color:${({ theme }) => theme.colors.lightGreen};
padding-top: 20px;


@media screen and (max-width: 768px) {
text-align: left;
}
`;

export const BoxInfo = styled.section`
width: 100%;
display:flex;
justify-content: space-evenly;
justify-items: center;
padding-top:20px;


@media screen and (max-width: 768px) {
 display: flex;
 flex-direction: column; 
}

`;

export const Box = styled.div`
font-family: 'Montserrat', sans-serif;
width: 25%;
display: flex;
flex-direction: column;
justify-content: center;
text-align: center;


@media screen and (max-width: 768px) {
width: 100%;
padding:5px;
}
`;

export const BoxTelefon1= styled(Link)`
font-family:'Montserrat', sans-serif;
color: ${({ theme }) => theme.colors.black};
font-size: 1.3rem;
font-weight: ${({ theme }) => theme.fontWeight.fontWeightBold};
text-align: center;
padding-top:20px;
line-height:1.8rem;
text-decoration: none;

&:hover {
  color: ${({ theme }) => theme.colors.lightGreen};
}
`;


export const BoxLocalionKod= styled.p`
font-family: 'Montserrat', sans-serif;
color: ${({ theme }) => theme.colors.black};
font-size: 1.3rem;
font-weight: ${({ theme }) => theme.fontWeight.fontWeightBold};
text-align: center;
padding-top: 20px;
line-height:1.8rem;
`;


export const BoxHoursWeek = styled.p`
font-family: 'Montserrat', sans-serif;
color: ${({ theme }) => theme.colors.black};
font-size: 1.3rem;
font-weight: ${({ theme }) => theme.fontWeight.fontWeightBold};
text-align: center;
padding-top: 20px;
line-height:1.8rem;
`;