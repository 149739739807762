import React from "react"
import Navbar from "../components/Navbar/Navbar"
import Kontakt from "../components/Kontakt/Kontakt"


import SEO from "../components/seo"
import { ThemeProvider } from "styled-components"
import { theme } from "../components/theme.js";

const Contact = () => (
  <>
   <ThemeProvider theme={theme}>
   <SEO 
   title="Kontakt"
   description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący leczenie psów, kotów, zwierząt futerkowych i gryzoni. 
    Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
    keywords="Sławomir Witczak, Lecznica dla Zwierząt"
   />

        <Navbar /> 
        <Kontakt />
        
   </ThemeProvider>
 </>  
)

export default Contact